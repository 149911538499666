import { useContext } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { AppContext } from "app-provider";
import { Formik, Form, Field, FieldArray } from "formik";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { Button } from "semantic-ui-react";
import {
  InfoPopup,
  Loading,
  DictionaryOptions,
  DictionaryList,
} from "components/ui";
import * as Yup from "yup";
import api from "api";
import { toast } from "react-toastify";

const getHeaderOptions = (importType, header_options) => {
  switch (importType) {
    case "Resupply":
    case "Demographics":
    case "Compliance":
    case "Snap":
      return header_options.resupply;
    //case "Backorder":
    //  return header_options.order;
    default:
      return [];
  }
};

const ImporterForm = ({ importType }) => {
  const { header_options } = useContext(AppContext);
  const { companyId } = useParams();
  const queryClient = useQueryClient();

  const companyImportSettings = useQuery(
    [`import-settings`, companyId, importType],
    async () => {
      const response = await api.get(
        `/api/import-settings/${companyId}/${importType}`,
      );
      return response.data;
    },
  );

  const updateImportSettings = useMutation(
    async (values) => {
      await api.put(`/api/import-settings/${companyId}/${importType}`, values);
      return values.companyId;
    },
    {
      onSuccess: (companyId) => {
        toast.success("Company Import Settings Updated");
        queryClient.invalidateQueries([
          "import-settings",
          companyId,
          importType,
        ]);
      },
    },
  );
  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
      {(companyImportSettings.isFetching || updateImportSettings.isLoading) && (
        <Loading />
      )}
      <Formik
        initialValues={{
          uses_headers: companyImportSettings.data?.uses_headers ?? true,
          dictionary: companyImportSettings.data?.dictionary ?? [],
          filename_constraint: companyImportSettings.data?.filename_constraint,
        }}
        validationSchema={Yup.object().shape({
          dictionary: Yup.array().of(
            Yup.object({
              header: Yup.string().required("Required"),
            }),
          ),
        })}
        onSubmit={(values) => {
          updateImportSettings.mutate({
            ...values,
            dictionary: values.dictionary.map((x, index) => ({
              ...x,
              priority: index + 1,
            })),
            companyId,
          });
        }}
        enableReinitialize
      >
        {({ values, resetForm }) => (
          <Form>
            <FieldArray
              name="dictionary"
              render={(arrayHelpers) => (
                <div
                  style={{
                    display: "grid",
                    padding: "0px 50px",
                    fontSize: "1.2em",
                    gridTemplateColumns: "1fr 2fr",
                    minHeight: 500,
                  }}
                >
                  <DictionaryOptions
                    dictionary={values.dictionary}
                    options={getHeaderOptions(importType, header_options)}
                    arrayHelpers={arrayHelpers}
                  />
                  <div>
                    <div
                      style={{
                        padding: 30,
                        display: "grid",
                        gridGap: 30,
                        gridTemplateColumns: "1fr auto auto",
                      }}
                    >
                      <label style={{ display: "flex", padding: 10 }}>
                        <Field type="checkbox" name="uses_headers" />
                        <span style={{ padding: "0px 20px" }}>
                          Uses Headers
                        </span>
                        <InfoPopup>
                          Import File has Headers, if not use column letters to
                          identiify which column in the file matches up with
                          data point
                        </InfoPopup>
                      </label>
                      <Button
                        type="button"
                        onClick={() => {
                          resetForm();
                        }}
                      >
                        Reset
                      </Button>
                      <Button primary>Save</Button>
                    </div>
                    <DictionaryList
                      dictionary={values.dictionary}
                      options={getHeaderOptions(importType, header_options)}
                      arrayHelpers={arrayHelpers}
                    />
                  </div>
                </div>
              )}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: "1.4em",
                margin: 30,
              }}
            >
              <label>Import notes/documentation</label>
              <Field as="textarea" name="notes" />
            </div>
            <div style={{ margin: 10 }}>
              <label style={{ display: "flex", padding: 10 }}>
                <span style={{ padding: "0px 20px" }}>
                  File Name Constraint
                </span>
                <Field type="text" name="filename_constraint" />
                <InfoPopup>
                  File Name constraint, checks for partial match via regular
                  expression
                </InfoPopup>
              </label>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

ImporterForm.propTypes = {
  importType: PropTypes.string.isRequired,
};

export default ImporterForm;
